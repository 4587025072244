import { Record, Map, List } from 'immutable'
import { normalize } from 'normalizr'

import { getPage } from '_utils/helpers'
import { createReducer } from '_utils/redux'
import Agency, { agencySchema } from '_models/agency'
import StaffUser, { transformOrderedMap } from '_models/staff-user'

import {
  GET_AGENCIES,
  HANDLE_SET_PAGE,
  GET_NEXT_PAGE_AGENCIES,
  HANDLE_SET_FILTER,
  GET_AGENCIES_FILTERED,
  UPDATE_AGENCY,
  GET_AGENCY,
  CREATE_AGENCY,
  ADD_AGENCY_STAFF_USER,
  UPDATE_AGENCY_STAFF_USER,
  DELETE_AGENCY_STAFF_USER,
  GET_AGENCY_RESPONSIBLE,
  GET_OPTIONS_FIELD_NAME,
  GET_AGENCIES_SIMPLE,
  GET_ADDITIONAL_TAKE_RATES_BY_AGENCY,
  GET_AGENCY_FULL,
  GET_MACRO_STEPS,
  GET_MACRO_STEP_BY_ID,
  GET_MACRO_STEPS_CUSTOM,
} from './actions'

const INITIAL_STATE = new Record({
  count: undefined,
  next: undefined,
  previous: undefined,
  results: Map(),
  order: Map(),
  page: 0,
  size: 10,
  filter: Map(),
  currentAgency: '',
  currentAgencyResponsible: [],
  loadedPages: List(),
  requestType: [],
  responsibleTeam: [],
  agenciesSimple: Map(),
  additionalTakeRates: undefined,
  macroSteps: [],
  macroStepsCustom: [],
  macroStep: {},
})()

export default createReducer(INITIAL_STATE, {
  [GET_AGENCIES.FULFILLED]: (state, { payload, meta }) => {
    const normalizeAgencies = normalize(payload.results, agencySchema)
    return state
      .set('results', Map(normalizeAgencies.entities.agency))
      .set('next', payload.next ? getPage(payload.next) : undefined)
      .set('count', payload.count)
      .set('previous', payload.previous)
      .set('size', meta.pageSize)
      .set('order', normalizeAgencies.result)
      .set('page', 0)
      .set('loadedPages', List([1]))
  },
  [GET_NEXT_PAGE_AGENCIES.FULFILLED]: (state, { payload }) => {
    const normalizeAgencies = normalize(payload.results, agencySchema)
    const requestedPage = Number.isNaN(getPage(payload.next))
      ? getPage(payload.next) - 1
      : state.get('page') + 1

    return state
      .set('count', payload.count)
      .set('next', payload.next ? getPage(payload.next) : undefined)
      .mergeIn(['results'], Map(normalizeAgencies.entities.agency))
      .mergeIn(['order'], normalizeAgencies.result)
      .updateIn(['loadedPages'], loadedPages =>
        state.loadedPages.includes(requestedPage) ? loadedPages : loadedPages.push(requestedPage)
      )
  },
  [GET_AGENCIES_FILTERED.FULFILLED]: (state, { payload }) => {
    const normalizeAgencies = normalize(payload.results, agencySchema)
    return state
      .set('count', payload.count)
      .set('next', payload.next ? getPage(payload.next) : undefined)
      .set('results', Map(normalizeAgencies.entities.agency))
      .set('page', 0)
  },
  [HANDLE_SET_PAGE]: (state, { payload }) => {
    return state.set('page', payload.page)
  },
  [HANDLE_SET_FILTER]: (state, { payload }) => {
    const { name, value } = payload
    return state.mergeIn(['filter'], { [name]: value })
  },

  [GET_AGENCY.FULFILLED]: (state, { payload }) => {
    return state
      .setIn(
        ['results', payload.id],
        new Agency(payload).set('staffUsers', transformOrderedMap(payload.staffUsers))
      )
      .set('currentAgency', payload.id)
  },

  [GET_AGENCY_FULL.FULFILLED]: (state, { payload }) => {
    return state
      .setIn(
        ['results', payload.id],
        new Agency(payload).set('staffUsers', transformOrderedMap(payload.staffUsers))
      )
      .set('currentAgency', payload.id)
  },

  [GET_AGENCIES_SIMPLE.FULFILLED]: (state, { payload }) => {
    return state.update('agenciesSimple', () => Map(payload))
  },

  [UPDATE_AGENCY.FULFILLED]: (state, { payload }) => {
    const agency = new Agency(payload)
    if (payload?.staffUsers) {
      agency.set('staffUsers', transformOrderedMap(payload.staffUsers))
    }
    return state.setIn(['results', payload.id], agency).set('currentAgency', payload.id)
  },
  [ADD_AGENCY_STAFF_USER.FULFILLED]: (state, { payload, meta: { agencyId } }) => {
    return state.setIn(
      ['results', agencyId, 'staffUsers', payload.id],
      new StaffUser({ ...payload, role: payload.getRole })
    )
  },
  [UPDATE_AGENCY_STAFF_USER.FULFILLED]: (state, { payload, meta: { agencyId } }) => {
    return state.setIn(
      ['results', agencyId, 'staffUsers', payload.id],
      new StaffUser({ ...payload, role: payload.getRole })
    )
  },
  [DELETE_AGENCY_STAFF_USER.FULFILLED]: (state, { meta: { agencyId, staffId } }) => {
    return state.deleteIn(['results', agencyId, 'staffUsers', staffId])
  },
  [CREATE_AGENCY.FULFILLED]: (state, { payload }) => {
    return state
      .setIn(['results', payload.id], new Agency(payload))
      .set('currentAgency', payload.id)
  },
  [GET_AGENCY_RESPONSIBLE.FULFILLED]: (state, { payload }) => {
    const mappedPayload = payload?.results?.map(({ name, id }) => ({
      label: name,
      value: id,
      name,
    }))
    return state.set('currentAgencyResponsible', mappedPayload)
  },
  [GET_OPTIONS_FIELD_NAME.FULFILLED]: (state, { payload }) => {
    const requestType = payload?.results
      ?.filter(({ field }) => field === 'request_type')
      .map(({ label, value }) => ({
        option: label,
        value,
      }))
    const responsibleTeam = payload?.results
      ?.filter(({ field }) => field === 'responsible_team')
      .map(({ label, value }) => ({
        option: label,
        value,
      }))
    return state.set('requestType', requestType).set('responsibleTeam', responsibleTeam)
  },
  [GET_ADDITIONAL_TAKE_RATES_BY_AGENCY.FULFILLED]: (state, { payload }) => {
    return state.set('additionalTakeRates', payload)
  },
  [GET_MACRO_STEPS.FULFILLED]: (state, { payload }) => {
    return state.update('macroSteps', () => payload)
  },
  [GET_MACRO_STEPS_CUSTOM.FULFILLED]: (state, { payload }) => {
    return state.update('macroStepsCustom', () => payload)
  },
  [GET_MACRO_STEP_BY_ID.FULFILLED]: (state, { payload }) => {
    return state.update('macroStep', () => payload)
  },
})
