import { createSelector } from 'reselect'
import {
  GET_AGENCIES,
  GET_NEXT_PAGE_AGENCIES,
  GET_AGENCIES_FILTERED,
} from '_modules/agency/actions'

export const agenciesSelector = state => state.agencies
export const hasNextPageSelector = state => state.agencies.next
export const agenciesResultSelector = state => state.agencies.results?.valueSeq()?.toArray()
export const agencySelector = state => state.agencies.results.get(state.agencies.currentAgency)
export const agenciesSimpleSelector = state => state.agencies.get('agenciesSimple')?.toJS()?.results
export const currentAgencySelector = state => state.agencies.currentAgency
export const currentAgencyResponsibleSelector = state => state.agencies.currentAgencyResponsible
export const requestTypeSelector = state => state.agencies.requestType
export const responsibleTeamSelector = state => state.agencies.responsibleTeam
export const additionalTakeRatesSelector = state => state.agencies.additionalTakeRates

export const getAgencyLogoSelector = createSelector(agencySelector, currentAgency =>
  currentAgency?.get('brandLogoPngUrl')
)

export const getAgencyShowReferaValue = createSelector(agencySelector, currentAgency =>
  currentAgency?.get('showReferaValue')
)

export const getAgenciesLoadingSelector = state =>
  !!state.loading.get(GET_AGENCIES.ACTION) ||
  !!state.loading.get(GET_NEXT_PAGE_AGENCIES.ACTION) ||
  !!state.loading.get(GET_AGENCIES_FILTERED.ACTION)

export const agenciesFormattedSelector = state => {
  const newOptions = [
    { value: '', label: '(Todos)' },
    { value: 'myAgencies', label: '*Minhas intermediárias' },
  ]
  const formattedAgencies = state.agencies.results
    ?.valueSeq()
    ?.toArray()
    .sort((first, second) => (first.name.toLowerCase() > second.name.toLowerCase() ? 1 : -1))
    .map(agencyItem => ({
      value: agencyItem.id,
      label: agencyItem.name,
      isActive: agencyItem.isActive,
    }))

  return newOptions.concat(formattedAgencies)
}

export const getMacroStepsListSelector = state => state?.agencies?.get('macroSteps')

export const getMacroStepSelector = state => state?.agencies?.get('macroStep')
export const getMacroStepCustomSelector = state => state?.agencies?.get('macroStepCustom')
